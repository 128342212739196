// components/Modal.tsx
import React, { useEffect } from "react";
import CreateButton from "../CreateButton/CreateButton";
import CancelButton from "../CancelButton/CancelButton";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AddRestaurantModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white px-12 py-5 w-[1000px] relative">
        <h2 className="mb-4 text-xl font-semibold">Add new restaurant</h2>
        <h2 className="mb-2 font-medium">Create new restaurant profile</h2>
        <form className="w-full">
          <div className="flex gap-4 mb-6">
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Restaurant Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Name"
                className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-semibold">
                Email Address <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="restaurant@gmail.com"
                className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                required
              />
            </div>
          </div>
          <div className="flex gap-4 mb-6">
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-semibold">
                Restaurant Logo <span className="text-red-600">*</span>
              </label>
              <label className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none flex items-center cursor-pointer">
                <div className="text-sm text-gray-400">
                  <h1>Upload</h1>
                </div>
                <input
                  type="file"
                  name="file_upload"
                  className="hidden"
                  id="image"
                />
              </label>
            </div>
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-semibold">
                Opening Hours <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="00:00 AM-00:00 PM"
                className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                required
              />
            </div>
          </div>
          <div className="flex gap-4 mb-6">
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-semibold">
                Owner's Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Full name"
                className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-semibold">
                Owner's email address <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="owner@gmail.com"
                className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                required
              />
            </div>
          </div>
          {/* RESTAURANT DETAILS */}
          <div>
            <h1 className="mt-8 mb-4 font-medium">Restaurant Details</h1>
          </div>
          <div className="flex gap-4 mb-6">
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-semibold">
                Location <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Icon Tower-Prishtine"
                className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-semibold">
                Phone Number <span className="text-red-600">*</span>
              </label>
              <input
                type="number"
                placeholder="+383 49 000000"
                className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                required
              />
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-8">
            <CreateButton />
            <CancelButton onClick={onClose} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddRestaurantModal;
