import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import {  clearErrors  } from "../../redux/slices/authAdminSlice";
import { login, fetchCurrentUser } from "../../redux/actions/authActions";
import type { RootState, AppDispatch } from "../../redux/store";
import LoginLogo from "../../assets/LoginLogo.png";

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { token, user, loading, error, emailError, passwordError } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    if (token && !user) {
      dispatch(fetchCurrentUser());
    }
  
    if (token && user?.role) {
      if (user.role === "ADMIN") {
        navigate("/admin-panel/add-new-food");
      } else if (user.role === "RESTAURANT_ADMIN") {
        navigate("/restaurant-profile");
      }
    }
  }, [token, user, navigate, dispatch]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors()); 
    await dispatch(login({ email, password }));
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-12 bg-bg-login/register">
      <img src={LoginLogo} alt="Login Logo" />
      <form onSubmit={handleSubmit} className="flex flex-col gap-2">
        <label className="text-sm">Email address</label>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email"
          className="w-[312px] h-[52px] rounded-xl pl-4 text-sm"
        />
        {emailError && <p className="text-sm text-red-500">{emailError}</p>}

        <div className="relative flex flex-col gap-2">
          <label className="text-sm">Password</label>
          <div className="relative w-[312px]">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="w-full h-[52px] rounded-xl pl-4 pr-10 text-sm"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 flex items-center right-3"
            >
              {showPassword ? <IoEyeOutline size={18} /> : <IoEyeOffOutline size={18} />}
            </button>
          </div>
          {passwordError && <p className="text-sm text-red-500">{passwordError}</p>}
        </div>

        <p className="mt-2 text-sm text-right">
          <NavLink to="">Forgot password?</NavLink>
        </p>
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : (
          <button
            type="submit"
            className="w-[312px] h-[52px] bg-loginButton rounded-xl text-white mt-8"
          >
            Sign In
          </button>
        )}
        {error && <p className="text-sm text-red-500">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
