import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { logout, setEmailError, setPasswordError } from "../slices/authAdminSlice"; 
import { AppDispatch } from "../store";


const LOGIN_URL = "https://dev.api.jomuntu.com/api/v1/users/login";
const CURRENT_USER_URL = "https://dev.api.jomuntu.com/api/v1/users/current-user";

export const isTokenExpired = (dispatch: AppDispatch) => {
  const expiration = localStorage.getItem("expiration");
  const isExpired = !expiration || Date.now() >= Number(expiration);
  
  if (isExpired) {
    dispatch(logout()); 
  }

  return isExpired;
};

// Login action
export const login = createAsyncThunk(
  "auth/login",
  async (credentials: { email: string; password: string }, { dispatch, rejectWithValue }) => {
    const { email, password } = credentials;

    if (!email) {
      dispatch(setEmailError("Please enter your email."));
      return rejectWithValue(null); 
    }

    if (!password) {
      dispatch(setPasswordError("Please enter your password."));
      return rejectWithValue(null); 
    }

    try {
      const response = await axios.post(LOGIN_URL, credentials);
      const { token, expiresIn } = response.data;

      localStorage.setItem("token", token);
      localStorage.setItem("expiration", String(Date.now() + expiresIn * 1000));

      dispatch(fetchCurrentUser());
      return token;
    } catch (error) {
      return rejectWithValue("Invalid login credentials");
    }
  }
);

// Fetch current user
export const fetchCurrentUser = createAsyncThunk(
  "auth/fetchCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(CURRENT_USER_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const { name, roles } = response.data;
      const role = roles[0]?.name || "user";

      return { name, role };
    } catch (error) {
      return rejectWithValue("Failed to fetch current user");
    }
  }
);
