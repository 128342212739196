import React from "react";
import CreateButton from "../../components/CreateButton/CreateButton";
import CancelButton from "../../components/CancelButton/CancelButton";

type Props = {};

const AddNewFood: React.FC = (props: Props) => {
  return (
    <div className="flex w-full">
      <div className="flex flex-col items-center flex-grow bg-white">
        <div className="w-full ">
          {/* ADD NEW FOOD */}
          <form>
            <div className="pt-8">
              <h1 className="text-2xl font-semibold">Add new food</h1>
              <p className="text-sm">
                In this section you can create a new food for the app
              </p>
            </div>
            <div className="flex flex-col gap-1 pt-6">
              <label className="text-sm font-semibold">
                Food image <span className="text-red-600">*</span>
              </label>
              <label className="w-full border-2 h-[124px] rounded-md border-dashed border-borderColor flex flex-col justify-center items-center cursor-pointer hover:border-customOrange">
                <div className="flex justify-center p-2 rounded-full bg-gray-50">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99994 17.75C8.58994 17.75 8.24994 17.41 8.24994 17V12.81L7.52994 13.53C7.23994 13.82 6.75994 13.82 6.46994 13.53C6.17994 13.24 6.17994 12.76 6.46994 12.47L8.46994 10.47C8.67994 10.26 9.00994 10.19 9.28994 10.31C9.56994 10.42 9.74994 10.7 9.74994 11V17C9.74994 17.41 9.40994 17.75 8.99994 17.75Z"
                      fill="#F85417"
                    />
                    <path
                      d="M10.9999 13.75C10.8099 13.75 10.6199 13.68 10.4699 13.53L8.46994 11.53C8.17994 11.24 8.17994 10.76 8.46994 10.47C8.75994 10.18 9.23994 10.18 9.52994 10.47L11.5299 12.47C11.8199 12.76 11.8199 13.24 11.5299 13.53C11.3799 13.68 11.1899 13.75 10.9999 13.75Z"
                      fill="#F85417"
                    />
                    <path
                      d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z"
                      fill="#F85417"
                    />
                    <path
                      d="M22 10.75H18C14.58 10.75 13.25 9.41999 13.25 5.99999V1.99999C13.25 1.69999 13.43 1.41999 13.71 1.30999C13.99 1.18999 14.31 1.25999 14.53 1.46999L22.53 9.46999C22.74 9.67999 22.81 10.01 22.69 10.29C22.57 10.57 22.3 10.75 22 10.75ZM14.75 3.80999V5.99999C14.75 8.57999 15.42 9.24999 18 9.24999H20.19L14.75 3.80999Z"
                      fill="#F85417"
                    />
                  </svg>
                </div>
                <h1>
                  <span className="font-medium text-customOrange">
                    Click To Upload
                  </span>{" "}
                  or drag and drop
                </h1>
                <h1 className="text-sm">(Max.File size: 25 MB)</h1>
                <input
                  type="file"
                  name="file_upload"
                  className="hidden"
                  id="image"
                />
              </label>
            </div>
            <div className="flex w-full gap-4 pt-6">
              <div className="flex flex-col w-4/12 gap-1">
                <label className="text-sm font-semibold">
                  Restaurant Name <span className="text-red-600">*</span>
                </label>
                <select
                  id="category"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none text-gray-400"
                >
                  <option selected disabled>Restaurant Name</option>
                </select>
              </div>
              <div className="flex flex-col w-4/12 gap-1">
                <label className="text-sm font-semibold">
                  Food Name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Food Name"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                />
              </div>
              <div className="flex flex-col w-4/12 gap-1">
                <label className="text-sm font-semibold">
                  Select Category <span className="text-red-600">*</span>
                </label>
                <select
                  id="category"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none text-gray-400 "
                >
                  <option selected disabled>
                    Select Category
                  </option>
                </select>
              </div>
            </div>
            {/* PROVIDER DETAILS */}
            <div className="pt-8">
              <div>
                <h1 className="text-2xl font-semibold">Provider Details</h1>
              </div>
              <div className="flex w-full gap-4 pt-4 ">
                <div className="flex flex-col w-4/12 gap-1">
                  <label className="text-sm font-semibold">
                    Phone Number <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    placeholder="+44 20 7123 4567"
                    className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                  />
                </div>

                <div className="flex flex-col w-4/12 gap-1">
                  <label className="text-sm font-semibold">
                    Price <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    placeholder="Price"
                    className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                  />
                </div>
                <div className="flex flex-col w-4/12 gap-1">
                  <label className="text-sm font-semibold">
                    Location <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Location"
                    className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex justify-end gap-4 mt-28">
                <CreateButton />
                <CancelButton />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewFood;
