import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
import EditButton from "../../components/EditButton/EditButton";
import DeleteButton from "../../components/DeleteButton/DeleteButton";
import AddRestaurantModal from "../../components/AddRestaurantModal/AddRestaurantModal";

type Props = {};

const activeStyle =
  "bg-customBrown w-[155px] h-[36px] justify-center flex items-center text-white rounded-lg text-sm";
const inactiveStyle =
  "bg-white w-[155px] h-[36px] text-black text-sm flex items-center justify-center";

const AllUsers: React.FC = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex w-full">
      <div className="w-full pt-8">
        <div>
          <h1 className="text-2xl font-semibold">All Users</h1>
          <p className="text-sm">
            In this section you can see all users, edit or add a new one
          </p>
        </div>
        <div className="mt-10 ">
          <button
            onClick={handleOpenModal}
            className="h-[36px] w-[204px] bg-customOrange text-sm text-white rounded-lg font-light flex justify-center gap-3"
          >
            <span className="flex items-center h-full">Add new restaurant</span>
            <span className="flex items-center h-full text-lg font-medium"><IoIosAdd size={24} color="white"/></span>
          </button>
        </div>
        {/* Add Restaurant Modal */}
        <AddRestaurantModal isOpen={isModalOpen} onClose={handleCloseModal} />

        {/* List of users or restaurants */}
        <div className="flex flex-col w-full mt-8">
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-2">
              <NavLink
                to=""
                className={({ isActive }) =>
                  isActive ? `${activeStyle}` : `${inactiveStyle}`
                }
              >
                List of all users
              </NavLink>
              <NavLink
                to=""
                className={({ isActive }) =>
                  isActive ? `${activeStyle}` : `${inactiveStyle}`
                }
              >
                List of all restaurants
              </NavLink>
            </div>
            <div className="relative w-[320px]">
              <input
                type="search"
                className="w-full h-[40px] border-2 rounded-lg pl-9 text-sm pr-2"
                placeholder="Search..."
              />
              <CiSearch
                className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
                size={16}
              />
            </div>
          </div>
          <div className="mt-9">
            <div>
              <div>
                <h1 className="font-medium">List:</h1>
              </div>
              <div className="mt-4">
                <table className="w-full">
                  <thead>
                    <tr className="h-[40px] border-b w-full">
                      <th className="w-2/12 pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                        Full Name
                      </th>
                      <th className="w-9/12 pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                        Email Address
                      </th>
                      <th className="w-2/12 "></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="h-[56px] bg-customGrey border-b">
                      <td className="w-2/12 pl-6 text-sm font-normal text-left border-r-2">
                        Erlira Hyseni
                      </td>
                      <td className="w-8/12 pl-6 text-sm border-r-2">
                        e.hyseni@linkplus-it.com
                      </td>
                      <td className="w-2/12">
                        <div className="flex justify-center gap-2 px-5">
                          <EditButton />
                          <DeleteButton />
                        </div>
                      </td>
                    </tr>
                    <tr className="h-[56px] bg-white border-b">
                      <td className="w-2/12 pl-6 text-sm font-normal text-left border-r-2">
                        Erlira Hyseni
                      </td>
                      <td className="w-8/12 pl-6 text-sm border-r-2">
                        e.hyseni@linkplus-it.com
                      </td>
                      <td className="w-2/12">
                        <div className="flex justify-center gap-2 px-5">
                          <EditButton />
                          <DeleteButton />
                        </div>
                      </td>
                    </tr>
                    <tr className="h-[56px] bg-customGrey border-b">
                      <td className="w-2/12 pl-6 text-sm font-normal text-left border-r-2">
                        Erlira Hyseni
                      </td>
                      <td className="w-8/12 pl-6 text-sm border-r-2">
                        e.hyseni@linkplus-it.com
                      </td>
                      <td className="w-2/12">
                        <div className="flex justify-center gap-2 px-5">
                          <EditButton />
                          <DeleteButton />
                        </div>
                      </td>
                    </tr>
                    <tr className="h-[56px] bg-white border-b">
                      <td className="w-2/12 pl-6 text-sm font-normal text-left border-r-2">
                        Erlira Hyseni
                      </td>
                      <td className="w-8/12 pl-6 text-sm border-r-2">
                        e.hyseni@linkplus-it.com
                      </td>
                      <td className="w-2/12">
                        <div className="flex justify-center gap-2 px-5">
                          <EditButton />
                          <DeleteButton />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
