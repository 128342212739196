import React from 'react'
import { FiEdit3 } from "react-icons/fi"
type Props = {}

const EditButton = (props: Props) => {
  return (
    <button className="border w-[40px] h-[40px] flex justify-center items-center rounded-lg border-gray-200 hover:bg-gray-50 bg-white">
          <FiEdit3 size={16}/>
    </button>
  )
}

export default EditButton