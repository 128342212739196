import React from "react";
import CreateButton from "../../components/CreateButton/CreateButton";
import CancelButton from "../../components/CancelButton/CancelButton";
import DeleteButton from "../../components/DeleteButton/DeleteButton";
import EditButton from "../../components/EditButton/EditButton";

type Props = {};

const CategoryAdmin: React.FC = (props: Props) => {
  return (
    <div className="flex w-full">
      <div className="w-full pt-8">
        {/* CREATE CATEGORY */}
        <div>
          <h1 className="text-2xl font-semibold">Category</h1>
          <p className="text-sm">
            In this section you can create and manage categories
          </p>
        </div>
        <div className="pt-14">
          <h1 className="text-lg font-semibold">Create Category</h1>
          <form>
            <div className="flex w-full pt-6">
              <div className="flex flex-col w-6/12 gap-1">
                <label className="text-sm font-semibold">
                  Add new category
                </label>
                <input
                  type="text"
                  placeholder="category name"
                  className="border h-[40px] rounded-md pl-4 text-sm"
                />
              </div>
              <div className="flex items-end justify-end w-6/12 gap-2">
                <CreateButton />
                <CancelButton />
              </div>
            </div>
          </form>
        </div>
        {/* CATEGORY TABLE */}
        <div className="w-full pt-12 text-sm font-semibold">
          <div>
            <h1>Manage all categories and subcategories</h1>
          </div>
          <div className="w-full pt-8">
            <table className="w-full">
              <thead>
                <tr className="h-[40px] border-b">
                  <th className="pl-6 font-medium text-left text-gray-500 border-r-2 ">
                    Category
                  </th>
                  <th className=""></th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-[56px] bg-customGrey border-b">
                  <td className="w-11/12 pl-6 text-sm font-normal text-left border-r-2">
                    Meal Type
                  </td>
                  <td className="w-1/12">
                    <div className="flex justify-center gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
                <tr className="w-11/12 h-[56px] border-b">
                  <td className="w-10/12 pl-6 text-sm font-normal text-left border-r-2">
                    Cuisine
                  </td>
                  <td className="w-1/12">
                    <div className="flex justify-center w-full gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
                <tr className="w-11/12 h-[56px] bg-customGrey border-b">
                  <td className="w-10/12 pl-6 text-sm font-normal text-left border-r-2">
                    Diete
                  </td>
                  <td className="w-1/12">
                    <div className="flex justify-center w-full gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
                <tr className="w-11/12 h-[56px] border-b">
                  <td className="w-10/12 pl-6 text-sm font-normal text-left border-r-2">
                    Others
                  </td>
                  <td className="w-1/12">
                    <div className="flex justify-center w-full gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryAdmin;
