import React from "react";
import { CiSearch } from "react-icons/ci";
import EditButton from "../../components/EditButton/EditButton";
import DeleteButton from "../../components/DeleteButton/DeleteButton";

type Props = {};

const AllPosts: React.FC = (props: Props) => {
  return (
    <div className="flex w-full">
      <div className="flex flex-col items-center flex-grow bg-white">
        <div className="w-full ">
          {/* ALL POSTS */}
          <div className="pt-8">
            <h1 className="text-2xl font-semibold">All posts</h1>
            <p className="text-sm">
              In this section you can see all posts, edit or delete them.
            </p>
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex items-end">
              <h1 className="font-medium">List:</h1>
            </div>
            <div className="relative w-[320px]">
              <input
                type="search"
                className="w-full h-[40px] border-2 rounded-lg pl-9 text-sm pr-2"
                placeholder="Search..."
              />
              <CiSearch
                className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
                size={16}
              />
            </div>
          </div>
          <div className="mt-6">
            <table className="w-full table-auto">
              <thead>
                <tr className="h-[40px] border-b w-full">
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Food Image
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Food Name
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Restaurant Name
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Price
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Category
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Contact Number
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Location
                  </th>
                  <th className=""></th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-[56px] border-b bg-customGrey">
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    <img
                      className="h-[36px] w-[43px]"
                      alt="food"
                      src="https://s3-alpha-sig.figma.com/img/a318/f673/719dd32542b3298a19542c7c350b9a3b?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RkOO~J7f66icZQjnuFph-Xf~VdQviqa72JDNisDPeYJAUKEcgyCeScwSCPoolzIiD86riVQvCrjHAZji-YEfnttTK4b-BfGvE3TGzO3k8t1oInsUWaBgavky798auWSpqLlwJKUevFwV1UP6fL1oXeAJHGQujdPMTcu~niUZAfDy5pv3sPnVRxrqBx0J~pGP8I9jZzZb8C5EDfWp4vZYXjyGkPS0V6bihx5AMiCnz2b3-DDSsGrHnGbVr4flZmm8V7~QERIA83KglBlGJPkkxJoJnNKcqFy~V6mfTxd1xQcY0lF0e4ONT5~6g8ZPZvBsJVFw1XGiXd90Au743QWiXA__"
                    />
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Pizza Peperoni
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Sach Pizza
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    6$
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Meal Type
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    +383 49000000
                  </td>
                  <td className="pl-6 text-sm border-r-2">
                    Icon Tower, Prishtine Icon Tower, Prishtine
                  </td>
                  <td className="w-1/12">
                    <div className="flex justify-center gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
                <tr className="h-[56px] bg-white border-b">
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    <img
                      className="h-[36px] w-[43px]"
                      alt="food"
                      src="https://s3-alpha-sig.figma.com/img/a318/f673/719dd32542b3298a19542c7c350b9a3b?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RkOO~J7f66icZQjnuFph-Xf~VdQviqa72JDNisDPeYJAUKEcgyCeScwSCPoolzIiD86riVQvCrjHAZji-YEfnttTK4b-BfGvE3TGzO3k8t1oInsUWaBgavky798auWSpqLlwJKUevFwV1UP6fL1oXeAJHGQujdPMTcu~niUZAfDy5pv3sPnVRxrqBx0J~pGP8I9jZzZb8C5EDfWp4vZYXjyGkPS0V6bihx5AMiCnz2b3-DDSsGrHnGbVr4flZmm8V7~QERIA83KglBlGJPkkxJoJnNKcqFy~V6mfTxd1xQcY0lF0e4ONT5~6g8ZPZvBsJVFw1XGiXd90Au743QWiXA__"
                    />
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Pizza Peperoni
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Sach Pizza
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    6$
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Meal Type
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    +383 49000000
                  </td>
                  <td className="pl-6 text-sm border-r-2">
                    Icon Tower, Prishtine
                  </td>
                  <td className="">
                    <div className="flex justify-center gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
                <tr className="h-[56px] border-b bg-customGrey">
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    <img
                      className="h-[36px] w-[43px]"
                      alt="food"
                      src="https://s3-alpha-sig.figma.com/img/a318/f673/719dd32542b3298a19542c7c350b9a3b?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RkOO~J7f66icZQjnuFph-Xf~VdQviqa72JDNisDPeYJAUKEcgyCeScwSCPoolzIiD86riVQvCrjHAZji-YEfnttTK4b-BfGvE3TGzO3k8t1oInsUWaBgavky798auWSpqLlwJKUevFwV1UP6fL1oXeAJHGQujdPMTcu~niUZAfDy5pv3sPnVRxrqBx0J~pGP8I9jZzZb8C5EDfWp4vZYXjyGkPS0V6bihx5AMiCnz2b3-DDSsGrHnGbVr4flZmm8V7~QERIA83KglBlGJPkkxJoJnNKcqFy~V6mfTxd1xQcY0lF0e4ONT5~6g8ZPZvBsJVFw1XGiXd90Au743QWiXA__"
                    />
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Pizza Peperoni
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Sach Pizza
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    6$
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Meal Type
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    +383 49000000
                  </td>
                  <td className="pl-6 text-sm border-r-2">
                    Icon Tower, Prishtine Icon Tower, Prishtine
                  </td>
                  <td className="">
                    <div className="flex justify-center gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
                <tr className="h-[56px] border-b bg-white">
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    <img
                      className="h-[36px] w-[43px]"
                      alt="food"
                      src="https://s3-alpha-sig.figma.com/img/a318/f673/719dd32542b3298a19542c7c350b9a3b?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RkOO~J7f66icZQjnuFph-Xf~VdQviqa72JDNisDPeYJAUKEcgyCeScwSCPoolzIiD86riVQvCrjHAZji-YEfnttTK4b-BfGvE3TGzO3k8t1oInsUWaBgavky798auWSpqLlwJKUevFwV1UP6fL1oXeAJHGQujdPMTcu~niUZAfDy5pv3sPnVRxrqBx0J~pGP8I9jZzZb8C5EDfWp4vZYXjyGkPS0V6bihx5AMiCnz2b3-DDSsGrHnGbVr4flZmm8V7~QERIA83KglBlGJPkkxJoJnNKcqFy~V6mfTxd1xQcY0lF0e4ONT5~6g8ZPZvBsJVFw1XGiXd90Au743QWiXA__"
                    />
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Pizza Peperoni
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Sach Pizza
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    6$
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Meal Type
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    +383 49000000
                  </td>
                  <td className="pl-6 text-sm border-r-2">
                    Icon Tower, Prishtine Icon Tower, Prishtine
                  </td>
                  <td className="">
                    <div className="flex justify-center gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
                <tr className="h-[56px] border-b bg-customGrey">
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    <img
                      className="h-[36px] w-[43px]"
                      alt="food"
                      src="https://s3-alpha-sig.figma.com/img/a318/f673/719dd32542b3298a19542c7c350b9a3b?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RkOO~J7f66icZQjnuFph-Xf~VdQviqa72JDNisDPeYJAUKEcgyCeScwSCPoolzIiD86riVQvCrjHAZji-YEfnttTK4b-BfGvE3TGzO3k8t1oInsUWaBgavky798auWSpqLlwJKUevFwV1UP6fL1oXeAJHGQujdPMTcu~niUZAfDy5pv3sPnVRxrqBx0J~pGP8I9jZzZb8C5EDfWp4vZYXjyGkPS0V6bihx5AMiCnz2b3-DDSsGrHnGbVr4flZmm8V7~QERIA83KglBlGJPkkxJoJnNKcqFy~V6mfTxd1xQcY0lF0e4ONT5~6g8ZPZvBsJVFw1XGiXd90Au743QWiXA__"
                    />
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Pizza Peperoni
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Sach Pizza
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    6$
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Meal Type
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    +383 49000000
                  </td>
                  <td className="pl-6 text-sm border-r-2">
                    Icon Tower, Prishtine Icon Tower, Prishtine
                  </td>
                  <td className="">
                    <div className="flex justify-center gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
                <tr className="h-[56px] border-b bg-white">
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    <img
                      className="h-[36px] w-[43px]"
                      alt="food"
                      src="https://s3-alpha-sig.figma.com/img/a318/f673/719dd32542b3298a19542c7c350b9a3b?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RkOO~J7f66icZQjnuFph-Xf~VdQviqa72JDNisDPeYJAUKEcgyCeScwSCPoolzIiD86riVQvCrjHAZji-YEfnttTK4b-BfGvE3TGzO3k8t1oInsUWaBgavky798auWSpqLlwJKUevFwV1UP6fL1oXeAJHGQujdPMTcu~niUZAfDy5pv3sPnVRxrqBx0J~pGP8I9jZzZb8C5EDfWp4vZYXjyGkPS0V6bihx5AMiCnz2b3-DDSsGrHnGbVr4flZmm8V7~QERIA83KglBlGJPkkxJoJnNKcqFy~V6mfTxd1xQcY0lF0e4ONT5~6g8ZPZvBsJVFw1XGiXd90Au743QWiXA__"
                    />
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Pizza Peperoni
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Sach Pizza
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    6$
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    Meal Type
                  </td>
                  <td className="pl-6 text-sm font-normal text-left border-r-2">
                    +383 49000000
                  </td>
                  <td className="pl-6 text-sm border-r-2">
                    Icon Tower, Prishtine Icon Tower, Prishtine
                  </td>
                  <td className="">
                    <div className="flex justify-center gap-2 px-5">
                      <EditButton />
                      <DeleteButton />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPosts;
