import React from 'react';

type Props = {
  onClick?: () => void; // Make onClick optional
};

const CancelButton: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      className="w-[132px] h-[40px] bg-white rounded-lg text-sm border"
      onClick={onClick} // Attach onClick handler here
    >
      CANCEL
    </button>
  );
};

export default CancelButton;
