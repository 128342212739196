import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isTokenExpired } from "../redux/actions/authActions"; 
import { logout } from "../redux/slices/authAdminSlice"; 

const useTokenExpiration = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired(dispatch)) {
        dispatch(logout());
      }
    }, 60000);

    return () => clearInterval(interval); 
  }, [dispatch]);
};

export default useTokenExpiration;
