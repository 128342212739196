import { Route, Routes } from "react-router";
import ProtectedAdminRoute from "./ProtectedRoutes/ProtectedAdminRoute";
import AddNewFood from "./pages/AdminPanel/AddNewFood";
import AllPosts from "./pages/AdminPanel/AllPosts";
import ReviewRequest from "./pages/AdminPanel/ReviewRequest";
import CategoryAdmin from "./pages/AdminPanel/CategoryAdmin";
import AllUsers from "./pages/AdminPanel/AllUsers";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import Login from "./pages/Login/Login";
import useTokenExpiration from "./hooks/useTokenExpiration";
import RestaurantProfile from "./pages/RestaurantAdmin/RestaurantProfile";

function App() {
  useTokenExpiration();
  return (
      <Routes>
      <Route path="login" element={<Login />} />

      <Route path="/restaurant-profile" element={<RestaurantProfile />} />

      <Route path="/admin-panel" element={<ProtectedAdminRoute />}>
        <Route path="" element={<AdminPanel />}>
          <Route path="add-new-food" element={<AddNewFood />} />
          <Route path="allposts" element={<AllPosts />} />
          <Route path="review-request" element={<ReviewRequest />} />
          <Route path="category" element={<CategoryAdmin />} />
          <Route path="allusers" element={<AllUsers />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
