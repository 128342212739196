import React from 'react'

type Props = {}

const RestaurantProfile = (props: Props) => {
  return (
    <div>RestaurantDashboard</div>
  )
}

export default RestaurantProfile