import React from 'react'

type Props = {}

const CreateButton = (props: Props) => {
  return (
    <button className='w-[132px] h-[40px] bg-customOrange text-white rounded-lg text-sm'>
        CREATE
    </button>
  )
}

export default CreateButton